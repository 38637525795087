/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import qs from 'qs'
import type {
  AppUserResponse,
  AppUsersListResponse,
  GetUsersParams,
  JSONAPIErrorResponse,
  ServicePermissionsResponse
} from './types'




/**
 * Gets a list of users.
 * @summary Get a list of users
 */
export const getUsers = (
    params?: GetUsersParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppUsersListResponse>> => {
    
    return axios.get(
      `/users`,{
    ...options,
        params: {...params, ...options?.params},
        paramsSerializer: (params) => qs.stringify(params, {"arrayFormat":"comma"}),}
    );
  }


export const getGetUsersQueryKey = (params?: GetUsersParams,) => {
    return [`/users`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersQueryOptions = <TData = Awaited<ReturnType<typeof getUsers>>, TError = AxiosError<JSONAPIErrorResponse>>(params?: GetUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = ({ signal }) => getUsers(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getUsers>>>
export type GetUsersQueryError = AxiosError<JSONAPIErrorResponse>


export function useGetUsers<TData = Awaited<ReturnType<typeof getUsers>>, TError = AxiosError<JSONAPIErrorResponse>>(
 params: undefined |  GetUsersParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUsers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetUsers<TData = Awaited<ReturnType<typeof getUsers>>, TError = AxiosError<JSONAPIErrorResponse>>(
 params?: GetUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUsers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetUsers<TData = Awaited<ReturnType<typeof getUsers>>, TError = AxiosError<JSONAPIErrorResponse>>(
 params?: GetUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get a list of users
 */

export function useGetUsers<TData = Awaited<ReturnType<typeof getUsers>>, TError = AxiosError<JSONAPIErrorResponse>>(
 params?: GetUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Gets a specific user.
 * @summary Get a specific user
 */
export const getUsersUserId = (
    userId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppUserResponse>> => {
    
    return axios.get(
      `/users/${userId}`,options
    );
  }


export const getGetUsersUserIdQueryKey = (userId: string,) => {
    return [`/users/${userId}`] as const;
    }

    
export const getGetUsersUserIdQueryOptions = <TData = Awaited<ReturnType<typeof getUsersUserId>>, TError = AxiosError<JSONAPIErrorResponse>>(userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserIdQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserId>>> = ({ signal }) => getUsersUserId(userId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersUserId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersUserIdQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserId>>>
export type GetUsersUserIdQueryError = AxiosError<JSONAPIErrorResponse>


export function useGetUsersUserId<TData = Awaited<ReturnType<typeof getUsersUserId>>, TError = AxiosError<JSONAPIErrorResponse>>(
 userId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUsersUserId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetUsersUserId<TData = Awaited<ReturnType<typeof getUsersUserId>>, TError = AxiosError<JSONAPIErrorResponse>>(
 userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUsersUserId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetUsersUserId<TData = Awaited<ReturnType<typeof getUsersUserId>>, TError = AxiosError<JSONAPIErrorResponse>>(
 userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get a specific user
 */

export function useGetUsersUserId<TData = Awaited<ReturnType<typeof getUsersUserId>>, TError = AxiosError<JSONAPIErrorResponse>>(
 userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetUsersUserIdQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Returns the current user and a list of their permissions.
 * @summary Get a list of permissions for the user
 */
export const getPermissions = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ServicePermissionsResponse>> => {
    
    return axios.get(
      `/permissions`,options
    );
  }


export const getGetPermissionsQueryKey = () => {
    return [`/permissions`] as const;
    }

    
export const getGetPermissionsQueryOptions = <TData = Awaited<ReturnType<typeof getPermissions>>, TError = AxiosError<JSONAPIErrorResponse>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPermissions>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPermissionsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPermissions>>> = ({ signal }) => getPermissions({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPermissions>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPermissionsQueryResult = NonNullable<Awaited<ReturnType<typeof getPermissions>>>
export type GetPermissionsQueryError = AxiosError<JSONAPIErrorResponse>


export function useGetPermissions<TData = Awaited<ReturnType<typeof getPermissions>>, TError = AxiosError<JSONAPIErrorResponse>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPermissions>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPermissions>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetPermissions<TData = Awaited<ReturnType<typeof getPermissions>>, TError = AxiosError<JSONAPIErrorResponse>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPermissions>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPermissions>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetPermissions<TData = Awaited<ReturnType<typeof getPermissions>>, TError = AxiosError<JSONAPIErrorResponse>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPermissions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get a list of permissions for the user
 */

export function useGetPermissions<TData = Awaited<ReturnType<typeof getPermissions>>, TError = AxiosError<JSONAPIErrorResponse>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPermissions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetPermissionsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



